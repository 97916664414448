import React from "react";
import {
    Chip,
    Badge,
    Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NightsStay } from '@material-ui/icons';
import { Draggable } from "react-beautiful-dnd";

export const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5),
        zIndex: 2,
    },
    tooltip: {
        whiteSpace: "pre-line"
    }
}));


export interface EmployeeChipOptions {
    emp: any,
    idx: number,
    nightBookings: any
}

function getStyle(style, snapshot) {
    if (!snapshot.isDragging) return {};
    if (!snapshot.isDropAnimating) {
        return style;
    }

    return {
        ...style,
        // cannot be 0, but make it super tiny
        transitionDuration: `0.001s`
    };
}


const EmployeeChip = ({ emp, idx, nightBookings }: EmployeeChipOptions): JSX.Element => {

    return <Draggable
        disableInteractiveElementBlocking={true}
        draggableId={emp.id} index={idx} key={emp.id} >
        {(provided, snapshot) => {
            if (snapshot.isDragging) {
                return <ChipperDraggingMemo emp={emp} provided={provided} snapshot={snapshot} />
            }
            return <ChipperMemo nightBookings={nightBookings} emp={emp} provided={provided} snapshot={snapshot} />
        }}
    </Draggable>;
}

export default React.memo(EmployeeChip)

export const Chipper = ({ nightBookings, emp, provided, snapshot }: any) => {
    const classes = useStyles()
    const empCount = nightBookings[emp.id] ? nightBookings[emp.id].length : undefined;
    let nightReq = false;
    let tooltip = nightBookings[emp.id] || "";
    if (tooltip.length > 0) {
        const tip = tooltip.map((nightAndWorkplace: any) => {
            const { night, workplaceName } = nightAndWorkplace;
            if (night) {
                nightReq = true;
                return `${workplaceName} - nat`;
            }
            return workplaceName;
        });
        tooltip = (
            <div className={classes.tooltip}>
                {tip.join(" \n")}
            </div>
        );
    }
    const label = `${emp.name} - ${emp.initials}`;
    return (
        <Badge
            color="secondary"
            badgeContent={empCount} // hide badge when draggin chip
            className={classes.chip}
        >
            <Tooltip placement="top" title={tooltip}>
                <Chip
                    size="small"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getStyle(provided.draggableProps.style, snapshot)}
                    label={label}
                    color={empCount ? "primary" : undefined}
                    icon={nightReq ? <NightsStay /> : undefined}
                ></Chip>
            </Tooltip>
        </Badge>
    );
}

export const ChipperDragging = ({ emp, provided, snapshot }: any) => {
    const label = `${emp.name} - ${emp.initials}`;
    const classes = useStyles()
    return (
        <Badge
            color="secondary"
            className={classes.chip}
        >
            <Chip
                size="small"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getStyle(provided.draggableProps.style, snapshot)}
                label={label}
            ></Chip>
        </Badge>
    );
}

const ChipperMemo = React.memo(Chipper)
export const ChipperDraggingMemo = React.memo(ChipperDragging)
