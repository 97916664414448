import React from "react";
import { Grid, Button } from "@material-ui/core";
import { WeekPaper } from "./WeekPaper";
import { makeStyles } from "@material-ui/core/styles";

import CalendarHeadingDay from "./CalendarHeadingDay";

export const useStyles = makeStyles(() => ({
    calendarHeadingContainer: {
        flexWrap: "nowrap",
        '&:hover': {
            cursor: 'grab'
        }
    }
}));


export function CalendarHeadingRow({ onPrevious, onNext, weekNo, days, selectedDay, setSelectedDay, calendarWidth, weekNoPlacement = "TOP" }: any) {
    const classes = useStyles()
    return <Grid
        container
        className={classes.calendarHeadingContainer}
        spacing={1}
        key={weekNo}
    >
        <WeekPaper
            title={`UGE ${weekNo}`}
            height={60}
            onNext={onNext}
            onPrevious={onPrevious}
        />

        {
            days.map((value: any, idx: any) => (<CalendarHeadingDay
                weekNoPlacement={weekNoPlacement}
                key={idx}
                idx={idx}
                value={value}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                calendarWidth={calendarWidth}
            />))
        }
        {weekNoPlacement === "TOP" ?
            <Button
                style={{
                    height: 60,
                    width: 100,
                    marginTop: 24
                }}
                onClick={onNext}
            >
                {"->"}
            </Button>
            : null}
    </Grid>
}

