import React from "react";
import { EmployeeRegistrationChip } from "./EmployeeRegistrationChip";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    employeesWrapper: {
        display: "flex",
        overflow: "auto",
        flexWrap: "wrap",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        zIndex: 3
    }
}));


export function EmployeeRegistrationChips({ renderNames, employees, nightBookings, selected, calendarEmployeeName, calenderEmployeeInitials, toggleNightRegistration, deleteRegistration }: any) {
    const classes = useStyles()
    return (
        <div
            className={classes.employeesWrapper}
        >
            {renderNames.sort((a: any, b: any) => {
                if (a.night && !b.night) {
                    return -1
                }
                if (a.night && b.night) {
                    return 0
                }
                if (!a.night && b.night) {
                    return 1
                }
                return 0
            }).map((booking: any, idx: number) => <EmployeeRegistrationChip
                key={booking.employeeId}
                employees={employees}
                booking={booking}
                selected={selected}
                nightBookings={nightBookings}
                idx={idx}
                calendarEmployeeName={calendarEmployeeName}
                calenderEmployeeInitials={calenderEmployeeInitials}
                deleteRegistration={deleteRegistration}
                toggleNightRegistration={toggleNightRegistration}
            />
            )}
        </div>
    );
}

