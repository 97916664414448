import React from "react";
import {
    Typography,
    Grid
} from "@material-ui/core";
import Day from "./Day";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    weekNumber: {
        height: 20,
        fontWeight: 300,
    },
}));

const CalendarHeadingDay = ({ idx, value, selectedDay, setSelectedDay, calendarWidth, weekNoPlacement = "TOP" }: any) => {
    const classes = useStyles()
    return <Grid
        item
        key={idx}
    >
        {weekNoPlacement === "TOP" ?
            <div className={classes.weekNumber}>
                {idx === 0 ? `ÅR ${value.year}` : idx % 7 === 0 ? `UGE ${value.weekNo}` : null}
            </div>
            : null}
        <Day
            justify={"center"}
            selected={selectedDay === idx}
            onClick={() => {
                setSelectedDay(idx === selectedDay ? undefined : idx);
            }}
            id={value.dayName}
            height={60}
            width={calendarWidth}
        >
            <Typography>{value.dayName}</Typography>
            <Typography>{value.momentDateFormatet}</Typography>
        </Day>
        {weekNoPlacement === "BELOW" ?
            <div style={{ position: 'relative' }}>

                <div className={classes.weekNumber} style={{
                    marginTop: -20,
                    position: 'absolute'
                    , bottom: -22
                }}>
                    {idx === 0 ? `ÅR ${value.year}` : idx % 7 === 0 ? `UGE ${value.weekNo}` : null}
                </div>
            </div>
            : null}
    </Grid>;
}


export default React.memo(CalendarHeadingDay)