import React from "react";
import { Typography, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(() => ({
  workplacePaper: {
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
    overflow: 'hidden',
  },
  paperGrid: {
    height: "100%",
    overflow: "auto",
    position: "relative",
  },
  grower: {
    width: 200,
    display: "flex",
    alignItems: "flex-end",
  },
}));

export function Workplace({
  title,
  subtitle,
  height = 100,
  width = 200,
  selected = false,
  onClick,
}: any) {
  const classes = useStyles();
  return (
    <Grid item className={classes.grower}>
      <Paper
        onClick={onClick}
        className={classes.workplacePaper}
        style={{
          height,
          width,
          backgroundColor: selected ? "#c6beff" : undefined,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'

        }}
      >
        <Typography align="center">{title}</Typography>
        <Typography color="primary" variant="caption" align="center">{subtitle}</Typography>
      </Paper>
    </Grid>
  );
}
