import React, { useState, useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";

import Day from "./Day";
import { EmployeeRegistrationChips } from "./EmployeeRegistrationChips";
import Fab from "@material-ui/core/Fab";
import { FlashOn, Delete, RadioButtonChecked } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Switch, CircularProgress, Input } from "@material-ui/core";
import { StickyNote } from "./StickyNote";

export const useStyles = makeStyles(() => ({
  utils: {
    position: "absolute",
    bottom: 0,
    right: 20,
    zIndex: 4,
    cursor: "pointer",
  },
}));

export function CalendarDay({
  note,
  saveNote,
  updateWorkplaceEmployee,
  counter,
  autoFill,
  workplace,
  idx,
  value,
  calendarHeight,
  calendarWidth,
  selectedDay,
  setSelectedDay,
  renderNames,
  employees,
  nightBookings,
  calendarEmployeeName,
  calenderEmployeeInitials,
  deleteRegistration,
  toggleNightRegistration,
}: any) {
  const classes = useStyles();
  const [showUtils, setShowUtils] = useState(false);
  const [writeNote, setWriteNote] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showLightningEmployees, setShowLightningEmployees] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [counter, setLoading]);

  return (
    <Droppable
      key={`${workplace.id}|${idx}`}
      droppableId={`${workplace.id}|${value.weekNoAndDay}`}
      direction="horizontal"
    >
      {(provided, snapshot) => {
        const selected = selectedDay === idx;
        return (
          <>
            <Day
              // key={snapshot.isDraggingOver + renderNames?.length + value.weekNoAndDay + + selected + ""}
              isDraggingOver={snapshot.isDraggingOver}
              watermark={workplace.name}
              height={calendarHeight}
              width={calendarWidth}
              selected={selected}
              onClick={() => {
                setSelectedDay(idx === selectedDay ? undefined : idx);
              }}
              dayName={value.dayName}
              id={value.weekNoAndDay}
              provided={provided}
              indianaScrollContainerIgnore={true}
              hideWaterMark={
                renderNames && renderNames.length > 0 ? true : false
              }
              renderHover={() => {
                if (!workplace.employees || loading) {
                  return null;
                }
                return (
                  <div className={classes.utils}>
                    <RadioButtonChecked
                      fontSize="small"
                      style={{ color: "lightgrey" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowUtils(true);
                      }}
                    />
                  </div>
                );
              }}
            >
              <div
                style={{
                  display: "flex",
                  overflow: "auto",
                  flexWrap: "wrap",
                  zIndex: 10,
                }}
              >
                {note || writeNote ? (
                  <StickyNote
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Input
                      tabIndex={-1}
                      defaultValue={note?.note}
                      onBlur={(e) => {
                        let submitter = note ?? {};
                        submitter["note"] = e.target.value;
                        saveNote({
                          workplace,
                          value,
                          note: submitter,
                        });
                      }}
                      inputComponent="textarea"
                      inputProps={{ tabIndex: -1 }}
                    />
                  </StickyNote>
                ) : (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setWriteNote(true);
                    }}
                    style={{
                      color: "lightgrey",
                      flex: 1,
                      textAlign: "center",
                      minWidth: calendarWidth,
                    }}
                  >
                    note
                  </div>
                )}
                <EmployeeRegistrationChips
                  selected={selected}
                  renderNames={renderNames}
                  employees={employees}
                  nightBookings={nightBookings}
                  calendarEmployeeName={calendarEmployeeName}
                  calenderEmployeeInitials={calenderEmployeeInitials}
                  toggleNightRegistration={(...props) => {
                    setLoading(true);
                    toggleNightRegistration(...props);
                  }}
                  deleteRegistration={(...props) => {
                    setLoading(true);
                    deleteRegistration(...props);
                  }}
                />
              </div>
              {provided.placeholder}
              {loading ? (
                <div className={classes.utils}>
                  <CircularProgress size={12} />
                </div>
              ) : null}
            </Day>
            {showUtils ? (
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    bottom: -10,
                    right: 0,
                    zIndex: 4,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      justifyContent: "space-around",
                      width: calendarWidth,
                    }}
                    onMouseLeave={() => {
                      setShowUtils(false);
                    }}
                  >
                    <Fab size="small">
                      <Delete
                        onClick={() => {
                          setLoading(true);
                          autoFill(workplace, value, renderNames, true);
                        }}
                      />
                    </Fab>
                    <Fab
                      size="small"
                      onMouseEnter={() => {
                        setShowLightningEmployees(true);
                      }}
                      onMouseLeave={() => {
                        setShowLightningEmployees(false);
                      }}
                    >
                      <Tooltip
                        placement="right"
                        open={showLightningEmployees}
                        interactive
                        title={workplace.employees
                          .filter((emplu: any) => employees[emplu.id])
                          .map((employee: any, idx) => {
                            return (
                              <div key={employee.id}>
                                {`${employee.name} - ${employee.initials}`}
                                <Switch
                                  size="small"
                                  color="primary"
                                  checked={employee.autoFill}
                                  onClick={() => {
                                    updateWorkplaceEmployee(
                                      workplace.id,
                                      idx,
                                      employee.autoFill ? false : true
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                      >
                        <FlashOn
                          onClick={() => {
                            setShowUtils(false);
                            setLoading(true);
                            autoFill(workplace, value, renderNames, false);
                          }}
                        />
                      </Tooltip>
                    </Fab>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        );
      }}
    </Droppable>
  );
}
