/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlannerRegistration = /* GraphQL */ `
  query GetPlannerRegistration($id: ID!) {
    getPlannerRegistration(id: $id) {
      id
      workplaceId
      employeeId
      departmentId
      weekNo
      day
      year
      night
      createdAt
      updatedAt
    }
  }
`;
export const listPlannerRegistrations = /* GraphQL */ `
  query ListPlannerRegistrations(
    $filter: ModelPlannerRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlannerRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workplaceId
        employeeId
        departmentId
        weekNo
        day
        year
        night
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlannerNote = /* GraphQL */ `
  query GetPlannerNote($id: ID!) {
    getPlannerNote(id: $id) {
      id
      workplaceId
      departmentId
      weekNo
      day
      year
      note
      createdAt
      updatedAt
    }
  }
`;
export const listPlannerNotes = /* GraphQL */ `
  query ListPlannerNotes(
    $filter: ModelPlannerNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlannerNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        workplaceId
        departmentId
        weekNo
        day
        year
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
