import React, { } from "react";
import { CalendarHeadingRow } from "./CalendarHeadingRow";
import { WorkplaceCalendarRow } from "./WorkplaceCalendarRow";


// import { makeStyles } from "@material-ui/core/styles";
// export const useStyles = makeStyles(() => ({
// }));

const Calendar = ({ notes, saveNote, onPrevious, onNext, updateWorkplaceEmployee, autoFill, weekNo, date, setDays, days, setDate, year, selectedDay, setSelectedDay, calendarWidth, workplaces, calendarHeight, employees, nightBookings, calendarEmployeeName, calenderEmployeeInitials, deleteRegistration, toggleNightRegistration, registrations }: any) => {
    // const classes = useStyles()

    return <>
        {
            Object.values(workplaces)
                .filter((workplaceFilter: any) => workplaceFilter.showInCalendar)
                .map((workplace: any, idx: any) => {
                    if (idx % 6 === 0) {
                        return (<React.Fragment key={`inskudt-${workplace.id}`}>
                            <CalendarHeadingRow
                                onPrevious={onPrevious}
                                onNext={onNext}
                                key={`calendar-heading-row-${workplace.id}`}
                                weekNo={weekNo}
                                days={days}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                calendarWidth={calendarWidth}
                                weekNoPlacement={idx === 0 ? 'TOP' : 'HIDDEN'}
                            />
                            <WorkplaceCalendarRow
                                notes={notes}
                                saveNote={saveNote}
                                updateWorkplaceEmployee={updateWorkplaceEmployee}
                                autoFill={autoFill}
                                deleteRegistration={deleteRegistration}
                                toggleNightRegistration={toggleNightRegistration}
                                key={`calendar-row-skittles-${workplace.id}`}
                                workplace={workplace}
                                calendarHeight={calendarHeight}
                                days={days}
                                registrations={registrations}
                                calendarWidth={calendarWidth}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                employees={employees}
                                nightBookings={nightBookings}
                                calendarEmployeeName={calendarEmployeeName}
                                calenderEmployeeInitials={calenderEmployeeInitials}
                            />
                        </React.Fragment>)
                    }
                    return (
                        <WorkplaceCalendarRow
                            notes={notes}
                            saveNote={saveNote}
                            updateWorkplaceEmployee={updateWorkplaceEmployee}
                            autoFill={autoFill}
                            deleteRegistration={deleteRegistration}
                            toggleNightRegistration={toggleNightRegistration}
                            key={`calendar-row-${workplace.id}`}
                            workplace={workplace}
                            calendarHeight={calendarHeight}
                            days={days}
                            registrations={registrations}
                            calendarWidth={calendarWidth}
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                            employees={employees}
                            nightBookings={nightBookings}
                            calendarEmployeeName={calendarEmployeeName}
                            calenderEmployeeInitials={calenderEmployeeInitials}
                        />
                    )
                })
        }
        <CalendarHeadingRow
            onPrevious={onPrevious}
            onNext={onNext}
            key={`calendar-row-last`}
            weekNo={weekNo}
            days={days}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            calendarWidth={calendarWidth}
            weekNoPlacement="BELOW"
        />
    </>
}

export { Calendar }
