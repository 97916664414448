import React from "react";
import {
    Typography,
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Workplace } from "./Workplace";
export function WorkplacesPanel({ setWorkplaces, workplaces }: any) {
    return (
        <ExpansionPanel
            defaultExpanded
            tabIndex={-1}
        >
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                tabIndex={-1}
            >
                <Typography style={{ paddingRight: 5 }} variant="h5">
                    Arbejdssteder
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                tabIndex={-1}
            >
                <Grid item xs={12}>
                    {/* <Grid container spacing={2} style={{ padding: 12 }}>
                        <AddWorkplace
                            onSubmit={({ name }: { name: string; }) => {
                                const id = nanoid();
                                setWorkplaces({
                                    ...workplaces,
                                    [id]: {
                                        id,
                                        name,
                                        showInCalendar: true,
                                    },
                                });
                            }} />
                    </Grid> */}
                    <Grid container direction="row" spacing={2}>
                        {Object.values(workplaces)
                            .map((workplace: any) => (
                                <Workplace
                                    subtitle={workplace.column}
                                    onClick={() => {
                                        const updateWorkplace = {
                                            [workplace.id]: {
                                                ...workplace,
                                                showInCalendar: !workplace.showInCalendar,
                                            },
                                        };
                                        setWorkplaces({ ...workplaces, ...updateWorkplace });
                                    }}
                                    key={workplace.id}
                                    height={100}
                                    title={workplace.name}
                                    selected={workplace.showInCalendar} />
                            ))}
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>);
}
