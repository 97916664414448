import React from "react";
import { Grid } from "@material-ui/core";
import { Workplace } from "./Workplace";
import { CalendarDays } from "./CalendarDays";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    workplaceCalendarRowContainer: {
        flexWrap: "nowrap"
    }
}));

export function WorkplaceCalendarRow({ notes, saveNote, updateWorkplaceEmployee, autoFill, workplace, registrations, calendarHeight, days, reqistrations, calendarWidth, selectedDay, setSelectedDay, employees, nightBookings, calendarEmployeeName, calenderEmployeeInitials, toggleNightRegistration, deleteRegistration }: any): any {
    const classes = useStyles()
    return <Grid
        container
        spacing={1}
        key={`WorkplaceCalendarRow-${workplace.id}`}
        className={classes.workplaceCalendarRowContainer}
    >
        <Workplace
            title={workplace.name}
            subtitle={workplace.column}
            height={calendarHeight}
        />
        <CalendarDays
            notes={notes}
            saveNote={saveNote}
            updateWorkplaceEmployee={updateWorkplaceEmployee}
            autoFill={autoFill}
            enableDropZone={false}
            days={days}
            reqistrations={reqistrations}
            workplace={workplace}
            calendarHeight={calendarHeight}
            calendarWidth={calendarWidth}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            employees={employees}
            nightBookings={nightBookings}
            calendarEmployeeName={calendarEmployeeName}
            calenderEmployeeInitials={calenderEmployeeInitials}
            registrations={registrations}
            toggleNightRegistration={toggleNightRegistration}
            deleteRegistration={deleteRegistration}
        />

    </Grid>
}
