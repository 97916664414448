import React from "react";
import { CalendarDay } from "./CalendarDay";


export function CalendarDays({ notes, saveNote, updateWorkplaceEmployee, autoFill, days, registrations, workplace, calendarHeight, calendarWidth, selectedDay, setSelectedDay, employees, nightBookings, calendarEmployeeName, calenderEmployeeInitials, deleteRegistration, toggleNightRegistration, enableDropZone }: any) {

    return days.map((value: any, idx: any) => {
        const reg: any = registrations?.[workplace.id]?.[value.weekNoAndDay];
        const note: any = notes?.[workplace.id]?.[value.weekNoAndDay]
        let counter = note ? 1 : 0
        let renderNames: any = []; //
        if (reg) {
            renderNames = Object.values(reg).map(
                (booking: any) => {
                    counter = counter + 1
                    if (booking.night) {
                        counter = counter + 1
                    }
                    return booking
                }
            );
        }
        return (
            <CalendarDay
                note={note}
                saveNote={saveNote}
                updateWorkplaceEmployee={updateWorkplaceEmployee}
                autoFill={autoFill}
                enableDropZone={enableDropZone}
                key={`${workplace.id}-${idx}-${value.weekNoAndDay}`}
                counter={counter}
                workplace={workplace}
                idx={idx}
                value={value}
                calendarHeight={calendarHeight}
                calendarWidth={calendarWidth}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                renderNames={renderNames}
                employees={employees}
                nightBookings={nightBookings}
                calendarEmployeeName={calendarEmployeeName}
                calenderEmployeeInitials={calenderEmployeeInitials}
                deleteRegistration={deleteRegistration}
                toggleNightRegistration={toggleNightRegistration}
            />
        );
    });
}
