import React from "react";
import {
  Typography,
  Grid,
  Button,
  Slider,
  Dialog,
  DialogTitle,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tooltip,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import { NightsStay, FlashOn } from "@material-ui/icons";

export function CalendarSettings({
  weekRange,
  changeWeekRange,
  departments = [],
  selectedDepartmentId,
  selectDepartment,
  getLoose,
  setLooseEmployeeContainer,
  resetEmployeeContainer,
  setSettingsBoolean,
  settingsBoolean,
  calendarHeight,
  setCalendarHeight,
  calendarWidth,
  setCalendarWidth,
  calendarEmployeeName,
  setCalendarEmployeeName,
  calenderEmployeeInitials,
  setCalenderEmployeeInitials,
}: any) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Tooltip
        style={{ alignSelf: "center", marginRight: 20 }}
        title={
          <div style={{ fontSize: 14, padding: 10, lineHeight: 1.5 }}>
            {"klik på en medarbejder for at lave registreringen om til nat"}
          </div>
        }
      >
        <NightsStay></NightsStay>
      </Tooltip>
      <Tooltip
        style={{ alignSelf: "center", marginRight: 20 }}
        title={
          <div style={{ fontSize: 14, padding: 10, lineHeight: 1.5 }}>
            {
              "klik på lynet for at lave en registrering for alle tildelte medarbejdere på arbejdsstedet"
            }
          </div>
        }
      >
        <FlashOn></FlashOn>
      </Tooltip>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setSettingsBoolean(!settingsBoolean)}
      >
        indstillinger
      </Button>
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={settingsBoolean}
        onClose={() => setSettingsBoolean(!settingsBoolean)}
      >
        <DialogTitle id="simple-dialog-title">Indstillinger</DialogTitle>
        <Grid spacing={1} container direction="column" style={{ padding: 24 }}>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="afdeling-select-label">Afdeling</InputLabel>
              <Select
                labelId="afdeling-select-label"
                id="afdeling-select"
                value={selectedDepartmentId}
                onChange={(e) => {
                  const selected = e.target.value as any;
                  selectDepartment(selected);
                }}
                inputProps={{
                  name: "age",
                  id: "age-native-simple",
                }}
              >
                {!Array.isArray(departments)
                  ? null
                  : departments
                      .filter((dep) => dep.name !== "Tilbud")
                      .map((department: any, idx: any) => {
                        return (
                          <MenuItem key={department.id} value={department.id}>
                            {department.name}
                          </MenuItem>
                        );
                      })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">
                Vis antal uger
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={weekRange}
                onChange={(e) => {
                  const selected = e.target.value;
                  changeWeekRange(selected);
                }}
              >
                <MenuItem value={1}>1 uge</MenuItem>
                <MenuItem value={2}>2 uger</MenuItem>
                <MenuItem value={3}>3 uger</MenuItem>
                <MenuItem value={4}>4 uger</MenuItem>
                <MenuItem value={5}>5 uger</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <div style={{ padding: 6 }}></div>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Slider
                orientation="horizontal"
                valueLabelDisplay="auto"
                defaultValue={100}
                step={10}
                marks
                min={10}
                max={200}
                aria-labelledby="vertical-slider"
                value={calendarHeight}
                onChange={(e, newValue) => setCalendarHeight(newValue)}
              />
              <Typography variant="body2">Kalender højde</Typography>
              <div style={{ padding: 6 }}></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Slider
                orientation="horizontal"
                valueLabelDisplay="auto"
                defaultValue={100}
                step={20}
                min={10}
                marks
                max={200}
                aria-labelledby="horizontal-slider"
                value={calendarWidth}
                onChange={(e, newValue) => setCalendarWidth(newValue)}
              />
              <Typography variant="body2">Kalender bredde</Typography>
              <div style={{ padding: 6 }}></div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Vis medarbejderens:</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={calendarEmployeeName}
                    onChange={() =>
                      setCalendarEmployeeName(!calendarEmployeeName)
                    }
                    name="name"
                  />
                }
                label="Navn"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={calenderEmployeeInitials}
                    onChange={() =>
                      setCalenderEmployeeInitials(!calenderEmployeeInitials)
                    }
                    name="initials"
                  />
                }
                label="Initialer"
              />
            </FormGroup>
            <Grid />
            <div style={{ padding: 6 }}></div>

            <Grid item xs={12}>
              <Typography variant="body2">Medarbejder boks:</Typography>
              <Button
                color="default"
                onClick={() => {
                  if (resetEmployeeContainer) {
                    resetEmployeeContainer({ position: true });
                  }
                }}
              >
                Nulstil position
              </Button>
              <Button
                color="default"
                onClick={() => {
                  if (resetEmployeeContainer) {
                    resetEmployeeContainer({ size: true });
                  }
                }}
              >
                Nulstil størrelse
              </Button>
              <Button
                color="default"
                onClick={() => {
                  if (resetEmployeeContainer) {
                    resetEmployeeContainer({ position: true, size: true });
                  }
                }}
              >
                Nulstil alt
              </Button>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={getLoose !== "skjul" && getLoose}
                      onChange={setLooseEmployeeContainer}
                      name="loose"
                    />
                  }
                  label="Frigjort"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={getLoose === "skjul"}
                      onChange={() =>
                        setLooseEmployeeContainer(
                          getLoose === "skjul" ? false : "skjul"
                        )
                      }
                      name="loose"
                    />
                  }
                  label="Skjul"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
