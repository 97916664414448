const awsmobile = {
    "aws_project_region": process.env.REACT_APP_aws_project_region,
    "aws_appsync_graphqlEndpoint": process.env.REACT_APP_aws_appsync_graphqlEndpoint,
    "aws_appsync_region": process.env.REACT_APP_aws_appsync_region,
    "aws_appsync_authenticationType": process.env.REACT_APP_aws_appsync_authenticationType,
    "aws_appsync_apiKey": process.env.REACT_APP_aws_appsync_apiKey
};


export default awsmobile;
