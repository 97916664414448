import React from "react";
import { Typography, Grid, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const useStyles = {
  paper: {
    height: 100,
    width: 100,
  },
  watermarkBase: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    padding: 10,
    paddingTop: "80vh",
    color: "grey",
    display: "block",
  },
  watermark: {
    display: "none",
  },
  paperGrid: {
    height: "100%",
    overflow: "auto",
    position: "relative",
    overflowX: "hidden",
  },
};

class Day extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      showHowerState: false,
      hoverTimer: undefined,
    };
  }
  render() {
    const {
      id,
      children,
      onClick,
      selected = false,
      height = 100,
      width = 100,
      justify = "flex-start",
      isDraggingOver = false,
      watermark,
      dayName,
      classes,
      indianaScrollContainerIgnore,
      provided,
      renderHover,
      onHover,
      onHoverExit,
      hideWaterMark,
    }: any = this.props;

    return (
      <Grid
        className={indianaScrollContainerIgnore ? "indy-ignore" : null}
        item
        ref={provided?.innerRef}
        {...provided?.droppableProps}
        onMouseEnter={() => {
          this.setState({
            hoverTimer: setTimeout(() => {
              this.setState({ showHoverState: true });
              if (onHover) {
                onHover();
              }
            }, 1),
          });
        }}
        onMouseLeave={() => {
          if (this.state.hoverTimer) {
            clearTimeout(this.state.hoverTimer);
          }
          this.setState({ showHoverState: false });
          if (onHoverExit) {
            onHoverExit();
          }
        }}
      >
        <Paper
          onClick={(e) => {
            this.setState({ showHoverState: !this.state.showHoverState });
            onClick(e);
          }}
          className={classes.paper}
          style={{
            width,
            height,
            backgroundColor: isDraggingOver
              ? "#fff2d7"
              : selected
              ? "floralwhite"
              : undefined,
          }}
        >
          <Grid
            className={classes.paperGrid}
            container
            justify={justify}
            alignItems="center"
            direction="column"
          >
            {children}
            {this.state.showHoverState && renderHover ? renderHover() : null}
            {hideWaterMark ? null : (
              <Typography
                className={
                  id === "søndag" || dayName === "søndag" || isDraggingOver
                    ? classes.watermarkBase
                    : classes.watermark
                }
              >
                {watermark}
              </Typography>
            )}
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

//@ts-ignore
export default withStyles(useStyles)(Day);
