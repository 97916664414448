import styled from "styled-components";
export const StickyNote = styled.div`
text-decoration:none;
color:#000;
background:#ffc;
display:block;
overflow: hidden;
`;

// -moz-box-shadow:5px 5px 7px rgba(33,33,33,1);
// -webkit-box-shadow: 5px 5px 7px rgba(33,33,33,.7);
// box-shadow: 5px 5px 7px rgba(33,33,33,.7);
// -moz-transition:-moz-transform .15s linear;
// -o-transition:-o-transform .15s linear;
// -webkit-transition:-webkit-transform .15s linear;