import React from "react";
import { Typography, Grid, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { WeekPaperContainer } from "./WeekPaperContainer";

const useStyles = makeStyles(() => ({
  workplacePaper: {
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  paperGrid: {
    height: "100%",
    overflow: "auto",
    position: "relative",
  },
  grower: {
    width: 200,
    display: "flex",
    alignItems: "flex-end",
  },
}));

export function WeekPaper({
  title,
  subtitle,
  onPrevious,
  onNext,
  height = 100,
  width = 200,
}: {
  title: string;
  subtitle?: string;
  onPrevious: () => void;
  onNext: () => void;
  height?: number;
  width?: number;
}): JSX.Element {
  const classes = useStyles();
  return (
    <Grid item className={classes.grower}>
      <Paper className={classes.workplacePaper} style={{ height, width }}>
        <WeekPaperContainer>
          <Button tabIndex={-1} onClick={onPrevious}>{"<"}</Button>
          <Grid
            direction="column"
            container
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Typography>{title}</Typography>
            <Typography>{subtitle}</Typography>
          </Grid>
          <Button tabIndex={-1} onClick={onNext}>{">"}</Button>
        </WeekPaperContainer>
      </Paper>
    </Grid>
  );
}
