/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPlannerRegistration = /* GraphQL */ `
  mutation CreatePlannerRegistration(
    $input: CreatePlannerRegistrationInput!
    $condition: ModelPlannerRegistrationConditionInput
  ) {
    createPlannerRegistration(input: $input, condition: $condition) {
      id
      workplaceId
      employeeId
      departmentId
      weekNo
      day
      year
      night
      createdAt
      updatedAt
    }
  }
`;
export const updatePlannerRegistration = /* GraphQL */ `
  mutation UpdatePlannerRegistration(
    $input: UpdatePlannerRegistrationInput!
    $condition: ModelPlannerRegistrationConditionInput
  ) {
    updatePlannerRegistration(input: $input, condition: $condition) {
      id
      workplaceId
      employeeId
      departmentId
      weekNo
      day
      year
      night
      createdAt
      updatedAt
    }
  }
`;
export const deletePlannerRegistration = /* GraphQL */ `
  mutation DeletePlannerRegistration(
    $input: DeletePlannerRegistrationInput!
    $condition: ModelPlannerRegistrationConditionInput
  ) {
    deletePlannerRegistration(input: $input, condition: $condition) {
      id
      workplaceId
      employeeId
      departmentId
      weekNo
      day
      year
      night
      createdAt
      updatedAt
    }
  }
`;
export const createPlannerNote = /* GraphQL */ `
  mutation CreatePlannerNote(
    $input: CreatePlannerNoteInput!
    $condition: ModelPlannerNoteConditionInput
  ) {
    createPlannerNote(input: $input, condition: $condition) {
      id
      workplaceId
      departmentId
      weekNo
      day
      year
      note
      createdAt
      updatedAt
    }
  }
`;
export const updatePlannerNote = /* GraphQL */ `
  mutation UpdatePlannerNote(
    $input: UpdatePlannerNoteInput!
    $condition: ModelPlannerNoteConditionInput
  ) {
    updatePlannerNote(input: $input, condition: $condition) {
      id
      workplaceId
      departmentId
      weekNo
      day
      year
      note
      createdAt
      updatedAt
    }
  }
`;
export const deletePlannerNote = /* GraphQL */ `
  mutation DeletePlannerNote(
    $input: DeletePlannerNoteInput!
    $condition: ModelPlannerNoteConditionInput
  ) {
    deletePlannerNote(input: $input, condition: $condition) {
      id
      workplaceId
      departmentId
      weekNo
      day
      year
      note
      createdAt
      updatedAt
    }
  }
`;
