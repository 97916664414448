import React, { useEffect, useState } from "react";
import {
    AppBar, Typography, Chip, Paper
} from "@material-ui/core";
import { Droppable } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core/styles";
import { CallMade, CallReceived } from '@material-ui/icons';

import EmployeeChip, { ChipperDraggingMemo } from "./EmployeeChip";
import ResizeObserver from 'rc-resize-observer';


export const useStyles = makeStyles((theme) => ({
    appBarStyle: {
        top: "auto",
        bottom: 0,
        backgroundColor: "#f1f1f1"
    },
    employeeContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: 'center',
        padding: 8
    },
    chip: {
        margin: theme.spacing(0.5),
        zIndex: 2,
    }
}));

// This method is needed for rendering clones of draggables
const getRenderItem = (items) => (provided, snapshot, rubric) => {
    const emp = Object.values(items)[rubric.source.index] as any;
    return (<ChipperDraggingMemo
        provided={provided}
        emp={emp}
        snapshot={snapshot}
    >
    </ChipperDraggingMemo>
    );
};


export function AppBottom({ employees, nightBookings, getLoose = false, onToggleGetLoose, setEmployeeContainerSize, employeeContainerSize, placement, setPlacement }: any) {
    const classes = useStyles()

    const [lastCalled, setLastCalled] = useState(Date.now())
    useEffect(() => {
        if (getLoose) {
            dragElement(document.getElementById("mydiv"), (top, left) => {
                setPlacement({
                    top, left
                })
            });
        }
    }, [getLoose, setPlacement])
    const child = <Droppable
        droppableId="droppable"
        direction="horizontal"
        isDropDisabled={true}
        renderClone={getRenderItem(employees)}

    >
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={classes.employeeContainer}
            >
                {!getLoose ?
                    <Typography style={{ paddingRight: 5 }} variant="h5">
                        Medarbejdere:
                    </Typography>
                    : null}
                {Object.values(employees).map((emp: any, idx) => {
                    const shouldRenderClone = emp.id === snapshot.draggingFromThisWith;
                    if (shouldRenderClone) {
                        return <Chip className={classes.chip} size="small" key={idx} label={`${emp.name} - ${emp.initials}`} />
                    }
                    return (<EmployeeChip
                        nightBookings={nightBookings}
                        emp={emp}
                        key={emp.id}
                        idx={idx}
                    />)
                })}
                <span
                    style={{
                        display: "none"
                    }}
                >
                    {provided.placeholder}
                </span>
            </div>
        )}
    </Droppable>
    if (getLoose) {
        return (
            <div style={{
                position: 'fixed',
                top: 0,
                bottom: 'auto',
                zIndex: 11,
                height: 200
            }}>

                <ResizeObserver
                    onResize={({ width, height }) => {
                        const now = Date.now()
                        if (now - lastCalled >= 300) {
                            setLastCalled(now)
                            setEmployeeContainerSize({
                                width, height
                            })
                        }
                    }}
                >
                    <Paper
                        id="mydiv"
                        style={{
                            position: 'absolute',
                            zIndex: 9,
                            maxHeight: '95vh',
                            maxWidth: '95vw',
                            backgroundColor: '#f1f1f1',
                            border: '1px solid lightgrey',
                            textAlign: 'center',
                            resize: 'both',
                            overflow: 'auto',
                            top: placement.top,
                            left: placement.left,
                            height: employeeContainerSize.height,
                            width: employeeContainerSize.width
                        }}>
                        <div style={{
                            paddingTop: 8,
                            cursor: 'move',
                            zIndex: 10,
                            backgroundColor: '#f1f1f1',

                        }} id="mydivheader">
                            <Typography style={{ paddingRight: 5 }} variant="h5">
                                Medarbejdere:
                    </Typography>
                            <div
                                style={{ position: 'absolute', left: 0, top: 0, cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}
                                onClick={() => {
                                    onToggleGetLoose()
                                }}
                            >
                                <CallReceived
                                    style={{ cursor: 'pointer' }}
                                >
                                </CallReceived>
                            </div>
                        </div>
                        {child}
                    </Paper>
                </ResizeObserver>
            </div>

        );
    }
    return (
        <AppBar
            position="fixed"
            color="inherit"
            className={classes.appBarStyle}
            variant="outlined"
        >
            <div
                style={{ position: 'absolute', right: 0, top: 0, cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}
                onClick={() => {
                    onToggleGetLoose()
                }}
            >
                frigør
                <CallMade
                    style={{ cursor: 'pointer' }}
                >
                </CallMade>
            </div>
            {child}
        </AppBar>
    )
}

function dragElement(elmnt, onChange) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id + "header")) {
        //@ts-ignore
        document.getElementById(elmnt.id + "header").addEventListener("touchstart", touchHandler, true);
        //@ts-ignore
        document.getElementById(elmnt.id + "header").addEventListener("touchmove", touchHandler, true);
        //@ts-ignore
        document.getElementById(elmnt.id + "header").addEventListener("touchend", touchHandler, true);
        //@ts-ignore
        document.getElementById(elmnt.id + "header").addEventListener("touchcancel", touchHandler, true);
        // if present, the header is where you move the DIV from:
        //@ts-ignore
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
    } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
    }

    function touchHandler(event) {
        var touch = event.changedTouches[0];

        var simulatedEvent = document.createEvent("MouseEvent");
        simulatedEvent.initMouseEvent({
            touchstart: "mousedown",
            touchmove: "mousemove",
            touchend: "mouseup"
        }[event.type], true, true, window, 1,
            touch.screenX, touch.screenY,
            touch.clientX, touch.clientY, false,
            false, false, false, 0, null);

        touch.target.dispatchEvent(simulatedEvent);
        event.preventDefault();
    }

    function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
        document.ontouchend = null;
        document.ontouchmove = null;
        onChange(elmnt.style.top, elmnt.style.left)
    }
}