import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import { DragDropContext } from "react-beautiful-dnd";
import { AppBottom } from "./AppBottom";
import { Calendar } from "./Calendar";
import { CalendarSettings } from "./CalendarSettings";
import { WorkplacesPanel } from "./WorkplacesPanel";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";
import * as ApiSpec from "./API";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports-override";
import { usePersistedState } from "./usePersistedState";
import ScrollContainer from "react-indiana-drag-scroll";
import * as queryString from "query-string";
import * as jwtDecode from "jwt-decode";
import "moment/locale/da";

Amplify.configure(awsconfig);

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: 8,
    paddingBottom: "100vh",
  },
}));

const AppWrapper = () => {
  const {
    token: tqs,
    departmentId = "55ae065a-2a66-4614-800e-c2b320134f00",
  } = queryString.parse(window.location.search);

  const [token, setToken] = usePersistedState("1234", tqs);
  const [weekRange, setWeekRange] = usePersistedState("week_range", 1);
  const weeker = [weekDays, twoWeeks, threeWeeks, fourWeeks, fiveWeeks];

  const setterDepartmentId = (id: string) => {
    window.location.replace(`${window.origin}/?departmentId=${id}`);
  };

  useEffect(() => {
    if (tqs) {
      setToken(tqs);
      window.history.pushState(
        departmentId,
        "Ceas Planner",
        `${window.origin}/?departmentId=${departmentId}`
      );
    }
  }, [tqs, departmentId, setToken]);

  let jwtToken = token;
  if (tqs) {
    jwtToken = tqs;
  }

  if (!jwtToken) {
    alert("noget gik galt - prøv igen");
    window.location.replace("https://app.ceas.io");
  }

  const canEdit = jwtDecode(token).role === "Funktionær" || jwtDecode(token)["https://ceas/role"] === "Funktionær";

  return (
    <App
      key={weekRange + departmentId}
      daysToRender={weeker[weekRange - 1]}
      weekRange={weekRange}
      changeWeekRange={setWeekRange}
      departmentId={departmentId}
      setDepartmentId={setterDepartmentId}
      token={jwtToken}
      canEdit={canEdit}
    />
  );
};

export default AppWrapper;

export function App({
  daysToRender,
  departmentId,
  changeWeekRange,
  weekRange,
  setDepartmentId,
  token,
  canEdit,
}: any) {
  //
  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [days, setDays] = useState<any>([]);
  const [date, setDate] = useState(moment().startOf("isoWeek"));

  useEffect(() => {
    const da = [
      ...daysToRender.map((dayName, idx) => {
        const registrationDate = moment(date).add(idx, "days");
        const weekNo = registrationDate.week();
        const weekDay = registrationDate.weekday();
        const year = registrationDate.year();
        const weekNoAndDay = `${weekNo}-${weekDay}`;
        return {
          dayName,
          weekNoAndDay,
          year,
          momentDateFormatet: registrationDate.format("D. MMM"),
          weekNo,
          weekDay,
        };
      }),
    ];
    setDays(da);
  }, [date, daysToRender]);

  const [department, setDepartment] = usePersistedState(
    "depar" + departmentId,
    {
      id: departmentId,
      name: "",
    }
  );
  const [selectedDay, setSelectedDay] = useState<number | undefined>(undefined);
  const [settingsBoolean, setSettingsBoolean] = useState<boolean>(false);

  const [departments, setDepartments] = usePersistedState("departments_v1", {});
  const [notes, setNotes] = usePersistedState("notes_v1" + departmentId, {});
  const [employees, setEmployees] = usePersistedState(
    "employees_v1" + departmentId,
    {}
  );
  const [placement, setPlacement] = usePersistedState("employee_placement", {
    top: "15vh",
    left: "85vw",
  });
  const [employeeContainerSize, setEmployeeContainerSize] = usePersistedState(
    "employee_employeeContainerSize",
    {
      height: "auto",
      width: "auto",
    }
  );
  const [workplaces, setWorkplaces] = usePersistedState(
    "workplaces_v1" + departmentId,
    {}
  );
  const [reqistrations, setRegistrations] = usePersistedState(
    "registrations_v1.1" + departmentId,
    {}
  );
  const [calendarHeight, setCalendarHeight] = usePersistedState(
    "calendarHeight",
    100
  );
  const [calendarWidth, setCalendarWidth] = usePersistedState(
    "calendarWidth",
    100
  );
  const [looseEmployeeContainer, setLooseEmployeeContainer] = usePersistedState(
    "looseEmployeeContainer",
    false
  );
  const [calendarEmployeeName, setCalendarEmployeeName] = usePersistedState(
    "calendarEmployeeName",
    false
  );
  const [
    calenderEmployeeInitials,
    setCalenderEmployeeInitials,
  ] = usePersistedState("calendarEmployeeInitials", true);

  useEffect(() => {
    createFerieSygIfNotExists(
      departmentId,
      workplaces,
      setWorkplaces,
      employees
    );
  }, [workplaces, setWorkplaces, employees, departmentId]);

  //@ts-ignore
  useEffect(() => {
    async function getRegistrations() {
      const url = process.env.REACT_APP_webApiEndPoint!
      try {
        const departments = await fetch(`https://${url}/api/department`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((resp) => resp.json());
        setDepartments(departments);

        const employees = await fetch(
          `https://${url}/api/department/${departmentId}/employees`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((resp) => resp.json())
          .then((employees) => {
            const objectified = {};
            if (Array.isArray(employees)) {
              employees.forEach((emp) => {
                objectified[emp.id] = emp;
              });
            }
            return objectified;
          });

        setEmployees(employees);
        const kanbanQuery = await fetch(
          `https://${url}/api/workplace/department/${departmentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ).then((resp) => resp.json());

        const relevantWorkplaces = {};
        const columns = [
          { index: 2, name: "Tildeles" },
          { index: 3, name: "Tildelt" },
          { index: 4, name: "Standby" },
          { index: 5, name: "Igang" },
        ];
        const fetchedWorkplaces = kanbanQuery.workplaces;
        columns.reverse().forEach((column) => {
          try {
            const ids = kanbanQuery.workplaceOrganizer[column.index].items;
            ids.forEach((workplaceId) => {
              const workplace = { ...fetchedWorkplaces[workplaceId] };
              relevantWorkplaces[workplaceId] = {
                ...workplace,
                employees: Object.values(workplace.employees).map(
                  (employee: any) => {
                    return {
                      ...employee,
                      autoFill: !employee.id.toLowerCase().startsWith("f"),
                    };
                  }
                ),
                showInCalendar: true,
                column: column.name,
              };
            });
          } catch (error) {
            console.log(error);
          }
        });

        setWorkplaces(relevantWorkplaces);
        setDepartment({
          id: departmentId,
          name: kanbanQuery.departmentName,
        });

        const dataNotes = (await API.graphql({
          query: queries.listPlannerNotes,
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        })) as any;
        let notes = {};
        //@ts-ignore
        if (
          dataNotes.data.listPlannerNotes &&
          dataNotes.data.listPlannerNotes.items
        ) {
          //@ts-ignore
          dataNotes.data.listPlannerNotes.items.forEach((reg: any) => {
            const weekNoAndDay = `${reg.weekNo}-${reg.day}`;
            notes = {
              ...notes,
              ...{
                [reg.workplaceId]: {
                  // @ts-ignore
                  ...notes[reg.workplaceId],
                  [weekNoAndDay]: reg,
                },
              },
            };
          });
        }
        setNotes(notes);

        let dataAen : any[] = []
        let nextToken = 'start'
        while( nextToken !== null) {

          const returnedData = await API.graphql({
            query: queries.listPlannerRegistrations,
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
            variables: {
              nextToken: nextToken === 'start' ? null : nextToken
            }
          }) as any;
          nextToken = returnedData?.data?.listPlannerRegistrations?.nextToken
          dataAen = [...dataAen, ...returnedData.data.listPlannerRegistrations.items]
        }

        let registrations = {};
        // @ts-ignore
        dataAen.forEach((reg: any) => {
          const emp = employees[reg.employeeId] || {
            id: reg.employeeId,
            name: "unknown",
            initials: "unknown",
          };
          const weekNoAndDay = `${reg.weekNo}-${reg.day}`;
          registrations = {
            ...registrations,
            ...{
              [reg.workplaceId]: {
                // @ts-ignore
                ...registrations[reg.workplaceId],
                [weekNoAndDay]: {
                  // @ts-ignore
                  ...registrations[reg.workplaceId]?.[weekNoAndDay],
                  [emp.id]: {
                    id: reg.id,
                    employeeId: emp.id,
                    employeeName: emp.name,
                    employeeInitials: emp.initials,
                    workplaceId: reg.workplaceId,
                    night: reg.night,
                    weekNoAndDay,
                  },
                },
              },
            },
          };
        });
        setRegistrations(registrations);
      } catch (error) {
        console.log(error);
        alert("noget gik galt");
        setOpenBackdrop(false);
        throw error;
      }
      setTimeout(() => {
        setOpenBackdrop(false);
      }, 1000);
    }
    try {
      getRegistrations();
    } catch (error) {
      alert("noget gik galt");
    }
  }, [
    departmentId,
    setDepartment,
    setEmployees,
    setRegistrations,
    setWorkplaces,
    setOpenBackdrop,
    setDepartments,
    setNotes,
    token,
  ]);

  const [nightBookings, setNightBookings] = useState<any>({});
  const year = date.year();
  const weekNo = date.week();

  return (
    <DragDropContext
      enableDefaultSensors={canEdit}
      key="idid"
      onDragEnd={async (e) => {
        if (e.destination) {
          if (!e.destination.droppableId.includes("|")) {
            return;
          }
          const workplaceId = e.destination.droppableId.split("|")[0];
          const weekNoAndDay = e.destination.droppableId.split("|")[1];
          const [weekNoSplitted, daySplitted] = weekNoAndDay.split("-");
          const emp = employees[e.draggableId];

          const oldState = { ...reqistrations };

          setRegistrations({
            ...reqistrations,
            ...{
              [workplaceId]: {
                ...reqistrations[workplaceId],
                [weekNoAndDay]: {
                  ...reqistrations[workplaceId]?.[weekNoAndDay],
                  [emp.id]: {
                    employeeId: emp.id,
                    employeeName: emp.name,
                    employeeInitials: emp.initials,
                    workplaceId: workplaceId,
                    weekNoAndDay,
                  },
                },
              },
            },
          });

          try {
            const resp: any = await API.graphql(
              graphqlOperation(mutations.createPlannerRegistration, {
                input: {
                  employeeId: emp.id,
                  workplaceId,
                  day: daySplitted,
                  weekNo: weekNoSplitted,
                  year: year + "",
                  departmentId: department.id,
                } as ApiSpec.CreatePlannerRegistrationInput,
              })
            );
            const id = resp.data.createPlannerRegistration.id;
            const newReqs = {
              ...reqistrations,
              ...{
                [workplaceId]: {
                  ...reqistrations[workplaceId],
                  [weekNoAndDay]: {
                    ...reqistrations[workplaceId]?.[weekNoAndDay],
                    [emp.id]: {
                      id,
                      employeeId: emp.id,
                      employeeName: emp.name,
                      employeeInitials: emp.initials,
                      workplaceId: workplaceId,
                    },
                  },
                },
              },
            };
            setRegistrations(newReqs);
            if (selectedDay + "" === daySplitted) {
              const tempNight = {};
              Object.values(newReqs).forEach((workplace: any) => {
                if (workplace[weekNoAndDay]) {
                  Object.keys(workplace[weekNoAndDay]).forEach((key) => {
                    if (!tempNight[key]) {
                      tempNight[key] = [];
                    }
                    tempNight[key] = [
                      ...tempNight[key],
                      {
                        night: workplace[weekNoAndDay][key].night || false,
                        workplaceName:
                          workplaces[workplace[weekNoAndDay][key].workplaceId]
                            .name,
                      },
                    ];
                  });
                }
              });
              setNightBookings(tempNight);
            }
            if (
              !workplaceId.startsWith("0ferie") &&
              !workplaceId.startsWith("0syg")
            ) {
              const currentWorkplace = workplaces[workplaceId];
              if (
                currentWorkplace &&
                currentWorkplace.employees.filter((e) => {
                  return e.id === emp.id || e.id === emp.id.toLowerCase();
                }).length === 0
              ) {
                const url = process.env.REACT_APP_webApiEndPoint
                await fetch(
                  `https://${url}/api/workplace/${workplaceId}/addemployee`,
                  {
                    method: "PUT",
                    headers: {
                      Authorization: `Bearer ${token}`,
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ id: emp.id }),
                  }
                ).then(() => {
                  currentWorkplace.employees.push(emp);
                });
              }
            }
          } catch (error) {
            console.log(error);
            alert("noget gik galt");
            setRegistrations(oldState);
          }
        }
      }}
    >
      <Grid container justify="space-between">
        <Typography style={{ padding: 12 }} variant="h2">
          {department.name}
        </Typography>
        <CalendarSettings
          selectDepartment={setDepartmentId}
          departments={departments}
          selectedDepartmentId={departmentId}
          weekRange={weekRange}
          changeWeekRange={changeWeekRange}
          getLoose={looseEmployeeContainer}
          setLooseEmployeeContainer={(hide) => {
            if (hide === "skjul") {
              setLooseEmployeeContainer("skjul");
            } else if (looseEmployeeContainer === "skjul") {
              setLooseEmployeeContainer(false);
            } else {
              setLooseEmployeeContainer(!looseEmployeeContainer);
            }
          }}
          resetEmployeeContainer={({ size, position }) => {
            if (position) {
              setPlacement({
                top: "15vh",
                left: "85vw",
              });
            }
            if (size) {
              setEmployeeContainerSize({
                height: "auto",
                width: "auto",
              });
            }
          }}
          setSettingsBoolean={setSettingsBoolean}
          settingsBoolean={settingsBoolean}
          calendarHeight={calendarHeight}
          setCalendarHeight={setCalendarHeight}
          calendarWidth={calendarWidth}
          setCalendarWidth={setCalendarWidth}
          calendarEmployeeName={calendarEmployeeName}
          setCalendarEmployeeName={setCalendarEmployeeName}
          calenderEmployeeInitials={calenderEmployeeInitials}
          setCalenderEmployeeInitials={setCalenderEmployeeInitials}
        />
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <ScrollContainer
          className="scroll-container"
          ignoreElements=".indy-ignore"
        >
          <Calendar
            notes={notes}
            onPrevious={() => {
              const newWeek = moment(date).add(-1, "week");
              setDate(newWeek);
            }}
            onNext={() => {
              const value = days[0];
              const newWeek = moment(
                value.momentDateFormatet + ` ${value.year}`,
                "D. MMM YYYY"
              ).add(1, "week");
              setDate(newWeek);
            }}
            updateWorkplaceEmployee={(workplaceId, employeeIdx, value) => {
              if (!canEdit) return;
              const empsss = workplaces[workplaceId].employees;
              const newEmployeeList = empsss
                .filter((emplu: any) => employees[emplu.id])
                .map((emp: any, idx: any) => {
                  if (idx === employeeIdx) {
                    return {
                      ...emp,
                      autoFill: value,
                    };
                  }
                  return emp;
                });
              const ehehe = {
                ...workplaces,
                [workplaceId]: {
                  ...workplaces[workplaceId],
                  employees: newEmployeeList,
                },
              };
              setWorkplaces(ehehe);
            }}
            autoFill={async (
              workplace,
              value,
              alreadyRegistered,
              deleteRegs
            ) => {
              if (!canEdit) return;

              if (deleteRegs) {
                const oldState = { ...reqistrations };
                const regi = { ...reqistrations };
                for (const booking of alreadyRegistered) {
                  const { workplaceId, weekNoAndDay, employeeId } = booking;
                  delete regi[workplaceId][weekNoAndDay][employeeId];
                }
                setRegistrations(regi);
                try {
                  for (const booking of alreadyRegistered) {
                    await API.graphql(
                      graphqlOperation(mutations.deletePlannerRegistration, {
                        input: {
                          id: booking.id,
                        } as ApiSpec.DeletePlannerRegistrationInput,
                      })
                    );
                  }
                } catch (error) {
                  console.log(error);
                  setRegistrations(oldState);
                  alert("noget gik galt");
                }
              } else {
                const workplaceId = workplace.id;
                const weekNoAndDay = value.weekNoAndDay;
                const weekNoSplitted = value.weekNo;
                const daySplitted = value.weekDay;
                let oldState = { ...reqistrations };
                const employeesFiltered = workplace.employees.filter(
                  (employee: any) => employee.autoFill && employees[employee.id]
                );

                const empRegs = {};
                employeesFiltered.forEach((emp: any) => {
                  empRegs[emp.id] = {
                    employeeId: emp.id,
                    employeeName: emp.name,
                    employeeInitials: emp.initials,
                    workplaceId: workplaceId,
                    weekNoAndDay,
                  };
                });
                let regi = {
                  ...reqistrations,
                  ...{
                    [workplaceId]: {
                      ...reqistrations[workplaceId],
                      [weekNoAndDay]: {
                        ...reqistrations[workplaceId]?.[weekNoAndDay],
                        ...empRegs,
                      },
                    },
                  },
                };
                setRegistrations(regi);

                try {
                  for (const emp1 of employeesFiltered) {
                    const emp = emp1 as any;
                    const resp: any = await API.graphql(
                      graphqlOperation(mutations.createPlannerRegistration, {
                        input: {
                          employeeId: emp.id,
                          workplaceId,
                          day: daySplitted,
                          weekNo: weekNoSplitted,
                          year: year + "",
                          departmentId: department.id,
                        } as ApiSpec.CreatePlannerRegistrationInput,
                      })
                    );
                    const id = resp.data.createPlannerRegistration.id;
                    const newReqs = {
                      ...regi,
                      ...{
                        [workplaceId]: {
                          ...regi[workplaceId],
                          [weekNoAndDay]: {
                            ...regi[workplaceId]?.[weekNoAndDay],
                            [emp.id]: {
                              id,
                              employeeId: emp.id,
                              employeeName: emp.name,
                              employeeInitials: emp.initials,
                              workplaceId: workplaceId,
                              weekNoAndDay,
                            },
                          },
                        },
                      },
                    };
                    setRegistrations(newReqs);
                    regi = { ...newReqs };
                    if (selectedDay + "" === daySplitted) {
                      const tempNight = {};
                      Object.values(newReqs).forEach((workplace: any) => {
                        if (workplace[weekNoAndDay]) {
                          Object.keys(workplace[weekNoAndDay]).forEach(
                            (key) => {
                              if (!tempNight[key]) {
                                tempNight[key] = [];
                              }
                              tempNight[key] = [
                                ...tempNight[key],
                                {
                                  night:
                                    workplace[weekNoAndDay][key].night || false,
                                  workplaceName:
                                    workplaces[
                                      workplace[weekNoAndDay][key].workplaceId
                                    ].name,
                                },
                              ];
                            }
                          );
                        }
                      });
                      setNightBookings(tempNight);
                    }
                  }
                } catch (error) {
                  alert("noget gik galt");
                  setRegistrations(oldState);
                }
              }
            }}
            toggleNightRegistration={async (booking: any) => {
              if (!canEdit) return;

              const night = booking.night ? false : true;
              const oldState = { ...reqistrations };
              const regi = { ...reqistrations };

              const newReqs = {
                ...regi,
                ...{
                  [booking.workplaceId]: {
                    ...regi[booking.workplaceId],
                    [booking.weekNoAndDay]: {
                      ...regi[booking.workplaceId]?.[booking.weekNoAndDay],
                      [booking.employeeId]: {
                        ...regi[booking.workplaceId]?.[booking.weekNoAndDay][
                          booking.employeeId
                        ],
                        night,
                      },
                    },
                  },
                },
              };
              setRegistrations(newReqs);
              try {
                await API.graphql(
                  graphqlOperation(mutations.updatePlannerRegistration, {
                    input: {
                      id: booking.id,
                      night,
                    } as ApiSpec.UpdatePlannerRegistrationInput,
                  })
                );
              } catch (error) {
                setRegistrations(oldState);
                alert("noget gik galt");
              }
            }}
            saveNote={async ({ workplace, value, note }) => {
              if (!canEdit) return;
              const oldState = { ...notes };
              try {
                const workplaceId = workplace.id;
                const weekNoSplitted = value.weekNo;
                const daySplitted = value.weekDay;
                const weekNoAndDay = value.weekNoAndDay;

                if (!note.id) {
                  const newNotes = {
                    ...notes,
                    ...{
                      [workplaceId]: {
                        ...notes[workplaceId],
                        [weekNoAndDay]: note,
                      },
                    },
                  };
                  setNotes(newNotes);
                  await API.graphql(
                    graphqlOperation(mutations.createPlannerNote, {
                      input: {
                        day: daySplitted,
                        departmentId: departmentId,
                        note: note.note,
                        workplaceId,
                        weekNo: weekNoSplitted,
                        year: value.year,
                      } as ApiSpec.CreatePlannerNoteInput,
                    })
                  );
                } else if (["", undefined, null].includes(note.note)) {
                  const newNotes = { ...notes };
                  delete newNotes[workplaceId]?.[weekNoAndDay];
                  setNotes(newNotes);
                  await API.graphql(
                    graphqlOperation(mutations.deletePlannerNote, {
                      input: {
                        id: note.id,
                      } as ApiSpec.DeletePlannerNoteInput,
                    })
                  );
                } else {
                  const newNotes = {
                    ...notes,
                    ...{
                      [workplaceId]: {
                        ...notes[workplaceId],
                        [weekNoAndDay]: note,
                      },
                    },
                  };
                  setNotes(newNotes);
                  await API.graphql(
                    graphqlOperation(mutations.updatePlannerNote, {
                      input: {
                        id: note.id,
                        note: note.note,
                      } as ApiSpec.UpdatePlannerNoteInput,
                    })
                  );
                }
              } catch (error) {
                alert("noget gik galt");
                setNotes(oldState);
              }
            }}
            weekNo={weekNo}
            date={date}
            setDays={setDays}
            days={days}
            setDate={setDate}
            year={year}
            selectedDay={selectedDay}
            setSelectedDay={(e: number | undefined) => {
              let tempNight: any = {};
              setSelectedDay(e);
              if (e !== undefined) {
                const registrationDate = moment(date).add(e, "days");
                const weekNo = registrationDate.week();
                const weekDay = registrationDate.weekday();
                const weekNoAndDay = `${weekNo}-${weekDay}`;
                Object.values(reqistrations).forEach((workplace: any) => {
                  if (workplace[weekNoAndDay]) {
                    Object.keys(workplace[weekNoAndDay]).forEach((key) => {
                      if (!tempNight[key]) {
                        tempNight[key] = [];
                      }
                      const workId = workplace[weekNoAndDay][key].workplaceId;
                      const worksOrNot = workplaces[workId] ?? {
                        name: `${workId}`,
                      };
                      tempNight[key] = [
                        ...tempNight[key],
                        {
                          night: workplace[weekNoAndDay][key].night || false,
                          workplaceName: worksOrNot.name,
                        },
                      ];
                    });
                  }
                });
              }
              setNightBookings(tempNight);
            }}
            calendarWidth={calendarWidth}
            workplaces={workplaces}
            calendarHeight={calendarHeight}
            deleteRegistration={async (booking: any) => {
              if (!canEdit) return;
              const oldState = { ...reqistrations };
              const regi = { ...reqistrations };
              const { workplaceId, weekNoAndDay, employeeId } = booking;
              delete regi[workplaceId][weekNoAndDay][employeeId];
              setRegistrations(regi);
              try {
                await API.graphql(
                  graphqlOperation(mutations.deletePlannerRegistration, {
                    input: {
                      id: booking.id,
                    } as ApiSpec.DeletePlannerRegistrationInput,
                  })
                );
              } catch (error) {
                setRegistrations(oldState);
                console.log(error);
                alert("noget gik galt");
              }
            }}
            registrations={reqistrations}
            employees={employees}
            nightBookings={nightBookings}
            calendarEmployeeName={calendarEmployeeName}
            calenderEmployeeInitials={calenderEmployeeInitials}
          />

          <div style={{ paddingTop: 40 }}></div>
          <WorkplacesPanel
            setWorkplaces={setWorkplaces}
            workplaces={workplaces}
          />
        </ScrollContainer>
      </Grid>
      {looseEmployeeContainer === "skjul" ? null : (
        <AppBottom
          placement={placement}
          setPlacement={setPlacement}
          setEmployeeContainerSize={setEmployeeContainerSize}
          employeeContainerSize={employeeContainerSize}
          getLoose={looseEmployeeContainer}
          onToggleGetLoose={() => {
            setLooseEmployeeContainer(!looseEmployeeContainer);
          }}
          setEmployees={setEmployees}
          employees={employees}
          nightBookings={nightBookings}
        />
      )}
      <Backdrop
        open={openBackdrop}
        style={{
          zIndex: 9999999999999,
          color: "#fff",
        }}
      >
        <CircularProgress />
      </Backdrop>
    </DragDropContext>
  );
}

export const weekDays = [
  "mandag",
  "tirsdag",
  "onsdag",
  "torsdag",
  "fredag",
  "lørdag",
  "søndag",
];

const twoWeeks = [...weekDays, ...weekDays];
const threeWeeks = [...twoWeeks, ...weekDays];
const fourWeeks = [...threeWeeks, ...weekDays];
const fiveWeeks = [...fourWeeks, ...weekDays];

export function removeFunktionærs(workplace: any) {
  return Object.values(workplace.employees).filter((emp: any) => {
    return !emp.id.toLowerCase().startsWith("f");
  });
}

function createFerieSygIfNotExists(
  departmentId,
  workplaces: any,
  setWorkplaces: any,
  employees: any
) {
  let id = "0ferie" + departmentId;
  if (!workplaces[id]) {
    setWorkplaces({
      [id]: {
        id,
        name: "Ferie / fri",
        showInCalendar: true,
        employees: Object.values(employees).map((emp: any) => ({
          ...emp,
          autoFill: false,
        })),
      },
      ...workplaces,
    });
  }
  id = "0syg" + departmentId;
  if (!workplaces[id]) {
    setWorkplaces({
      [id]: {
        id,
        name: "Sygdom",
        showInCalendar: true,
      },
      ...workplaces,
    });
  }
}
