import React, { useEffect } from "react";
export function usePersistedState(key: string, defaultValue: any) {
  // @ts-ignore
  const ls = JSON.parse(localStorage.getItem(key))
  const [state, setState] = React.useState(
    () => ls === undefined || ls === null ? defaultValue : ls
  );
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}
