import React from "react";
import {
    Chip,
    Tooltip
} from "@material-ui/core";
import { NightsStay } from '@material-ui/icons';

import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5),
        zIndex: 2,
    },
    tooltip: {
        whiteSpace: "pre-line"
    },
    tooltipZIndex: {
        zIndex: 2
    },
}));

function EmployeeRegistrationChip2({ employees, booking, nightBookings, selected, idx, calendarEmployeeName, calenderEmployeeInitials, deleteRegistration, toggleNightRegistration }: any) {
    const classes = useStyles()

    const { name, initials, id } = employees[booking.employeeId] || { name: 'ukendt', initials: '?', id: 'ukendt' };


    let tooltip = nightBookings[id] || "";
    if (tooltip.length > 0) {
        const tip = tooltip.map((nightAndWorkplace: any) => {
            const { night, workplaceName } = nightAndWorkplace
            if (night) {
                return `${workplaceName} - nat`
            }
            return workplaceName
        })
        tooltip = (
            <div className={classes.tooltip}>
                {tip.join(" \n")}
            </div>
        );
    }

    let label = ""
    if (calendarEmployeeName && calenderEmployeeInitials) {
        label = `${name} - ${initials}`
    } else if (calendarEmployeeName) {
        label = name
    } else if (calenderEmployeeInitials) {
        label = initials
    }
    const nightReq = booking.night
    return (
        <Tooltip
            key={idx}
            title={selected ? tooltip : ""}
            placement="right"
            className={classes.tooltipZIndex}
        >
            <Chip
                size="small"
                color="primary"
                className={classes.chip}
                key={idx}
                label={label}
                onDelete={() => {
                    deleteRegistration(booking)
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    toggleNightRegistration(booking)

                }}
                icon={nightReq ? <NightsStay /> : undefined}
            />
        </Tooltip>
    );
}


export const EmployeeRegistrationChip = React.memo(EmployeeRegistrationChip2)